import { UserRole } from "../helpers/authHelper";

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'he';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'he', name: 'עברית', direction: 'rtl' },
];


export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
  token: '',
}


export const adminRoot = '/app';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const MUIKey = 'da4e9bb65eb7965c717e9ef7ee4d5aefTz00NTk4OCxFPTE2ODczNDM4OTUwNjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
export const googleMapKey = 'AIzaSyD6fVc-MZWZQFKWr4f32VONcAWFRakY7kw';
export const isMultiColorActive = true;
export const defaultColor = 'light.greenmoss';
export const isDarkSwitchActive = true;
export const defaultDirection = 'rtl';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const defaultPageSize = 10;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
