import axios from 'axios';
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

const AxiosInterceptor = ({ children }) => {

    const history = useHistory();

    useEffect(() => {

        const resInterceptor = response => {
            return response;
        }

        const reqInterceptor = config => {
            const token = localStorage.getItem('auth_token');
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            config.headers['Content-Type'] = 'application/json';
            return config;
        }

        const errReqInterceptor = error => {
            return Promise.reject(error);
        }

        const errInterceptor = error => {
            const originalRequest = error.config;
            if (error.response?.status === 401 && error.response?.data?.message === "Error 8888") {
                history.push('/unauthorized');
                return Promise.reject(error);
            }
            if (error.response?.status === 401 && originalRequest.url == '/account/signInAdmin') { // Added this condition to avoid infinite loop 
                // Navigate to any unauthorised route to avoid infinite loop...
                return Promise.reject(error);
            }
            if (error.response?.status === 401 && !originalRequest._retry) { // Code inside this block will refresh the auth token
                // localStorage.removeItem('auth_token');
                originalRequest._retry = true;
                const refreshToken = 'xxxxxxxxxx'; // Write the  logic  or call here the function which is having the login to refresh the token.
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token');
                return axios.get(process.env.REACT_APP_API_BASE_URL + '/account/loginAdminRefresh')
                    .then(res => {
                        if (res.status === 200) {
                            localStorage.setItem('auth_token', res.data.value);
                            originalRequest.headers.Authorization = 'Bearer ' + localStorage.getItem('auth_token');
                            return axios(originalRequest);
                        }
                    })
            }

            return Promise.reject(error);
        }


        const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor);
        const interceptor2 = api.interceptors.request.use(reqInterceptor, errReqInterceptor);

        return () => api.interceptors.response.eject(interceptor, interceptor2);

    }, [history])

    return children;
}


export default api;
export { AxiosInterceptor }